import {
  createRouter as createVueRouter,
  createWebHashHistory,
  Router,
} from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Training from "../views/Training.vue";
import MeetTheTeam from "../views/MeetTheTeam.vue";
import CollinsUniversity from "../views/training/CollinsUniversity.vue";
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";

export function createRouter(app: App): Router {
  return createVueRouter({
    routes: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/training",
        name: "training",
        component: Training,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/training/collins-university",
        name: "CollinsUniversity",
        component: CollinsUniversity,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/meet-the-team",
        name: "MeetTheTeam",
        component: MeetTheTeam,
        beforeEnter: createAuthGuard(app),
      },
    ],
    history: createWebHashHistory(),
  });
}
