<template>
  <div class="text-center hero">
    <img
      class="mb-3 app-logo"
      src="../assets/collins-logo.png"
      alt="Collins logo"
      width="400"
    />
    <h1 class="mb-4">Collins Orthodontics</h1>
    <div class="wrap-div">
      <p class="lead">
        You are viewing the internal site for Collins Orthodontics, check us out
        at
        <a href="https://www.rochesterorthodontics.com/" target="_blank"
          ><font-awesome-icon icon="link" class="mr-2" />Rochester
          Orthodontics</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style lang="css" scoped>
.wrap-div {
  inline-size: auto;
  overflow-wrap: break-word;
}
</style>
