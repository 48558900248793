<template>
  <div class="container">
    <h1>Collins Training</h1>
    <p>List of training materials for</p>

    <h2>Paths</h2>
    <ul>
      <li>Getting started at Collins</li>
      <li>Dental assistants</li>
      <li>Lab techs</li>
      <li>Front desk</li>
      <li>Back office</li>
      <li>Donut staff</li>
    </ul>

    <h2>Collins University</h2>
    <div class="vue-link">
      <font-awesome-icon icon="link" class="mr-2" /><router-link
        to="/training/collins-university"
        >Collins University</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "training",
};
</script>
