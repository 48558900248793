<template>
  <div class="container">
    <h1>Collins University</h1>

    <p>Ready to take it to the next level?</p>
  </div>
</template>

<script>
export default {
  name: "CollinsUniversity",
};
</script>
