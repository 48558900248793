<template>
  <div class="next-steps">
    <h2 class="my-5 text-center">What can I do next?</h2>

    <div class="row">
      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/connections">
            <font-awesome-icon icon="link" class="mr-2" />Request Account
          </a>
        </h6>
        <p>
          Sign up for an account to see training materials, team information,
          and more!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContent",
};
</script>
